export const COMPANY_EMAIL_DOMAIN = 'appius.ai';
export const SUPPORT_EMAIL = `patrick@${COMPANY_EMAIL_DOMAIN}`;
export const EMAIL_SENDER_EMAIL = `team@${COMPANY_EMAIL_DOMAIN}`;
export const PUBLIC_SUPPORT_EMAIL = `info@${COMPANY_EMAIL_DOMAIN}`;
export const CONTACT_EMAIL = 'info@appius.ai';
export const COMPANY_NAME = 'Appius';
export const COMPANY_NAME_FULL = 'Appius Software GmbH';
export const COMPANY_ADDRESS = 'Ahornallee 24, 87719 Mindelheim, Germany';
export const COMPANY_VAT_ID = 'DE361952314';
export const DISTRICT_COURT = 'Memmingen HRB 21453';
export const COMPANY_URL = 'https://appius.ai';
export const PLATFORM_URL = 'https://app.appius.ai';

export const DATA_PROTECTION_OFFICER_EMAIL = 'datenschutz@appius.ai';
export const DATA_PROTECTION_OFFICER_ADDRESS = 'Ahornallee 24, 87719 Mindelheim, Germany';
export const DATA_PROTECTION_OFFICER_NAME = 'Patrick Rogg';
